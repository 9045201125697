/* GENERAL */

.dropdown_icon {
    font-size: 1.2rem;
    color: #6c757d;
    float: right;
}

.action_menu {
    width: 2rem;
    margin-right: 5px;
}

.permissionGroupAction {
    margin-right: 5px;
}

.margin_top_row {
    margin-top: 2rem;
}

.edit_header_icon_add{
    cursor: pointer;
    margin-right: 15px;
    vertical-align: sub;
    font-size: 1.5rem;
    color: var(--primary--color);
}

.edit_header_icon_save {
    cursor: pointer;
    margin-right: 15px;
    font-size: 1.5rem;
    color: var(--primary--color)
}

.edit_header_icon_close {
    cursor: pointer;
    font-size: 1.5rem;
    color: grey
}
.fullHeight {
    height: 95vh;
}